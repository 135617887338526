body {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

.notify_count {
  position: absolute;
  top: -5px;
  right: -8px;
  font-size: 11px;
  background: #dc3545;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-dropdown {
  min-width: 350px !important;
  overflow-x: hidden;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.img-fluid.proofThumb {
  max-height: 135px;
  border-radius: 5px;
  display: block;
  margin: 15px 0 0;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  color: #fff;
  border: 2px solid #fff;
  padding: 5px 7px;
  margin: 0 0px 0 0;
  line-height: 0;
  position: absolute;
  top: 25px;
  z-index: 9;
  left: 15px;
}

.navbar.adminNav {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 65px;
}

.text-small {
  font-size: 0.9rem;
}

.chat-box {
  height: 510px;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
    margin-top: 1%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }

  .adminNav .dropdown-menu a {
    display: block;
    color: #000;
    padding: 8px 10px;
  }
}

#as-react-datatable-container select {
  width: 70px !important;
}

.container input {
  position: static !important;
  opacity: 1 !important;
  cursor: pointer !important;
  height: auto !important;
  width: 100% !important;
}

.container [type="checkbox"] {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
  height: 0 !important;
  width: 0 !important;
}

.chip-body {
  height: 37px !important;
}

.multiSelect {
  margin: 0 !important;
}

.tableHead h2 {
  font-size: 24px;
  color: #d6b032;
  text-align: left;
  margin: 12px 15px 15px;
  font-weight: 600;
}

.loadingContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #000; */
  overflow: hidden;
}

.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loading div {
  position: absolute;
  background: #032eff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -0.7s;
}

@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.chat_content_center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e6e6e6;
}

.chat_content_center p {
  margin: 0;
  text-align: center;
}

.chat_content_center h4 {
  margin: 0;
  font-weight: 600;
  padding: 4px 0px;
  color: #0b3e8f;
}

.chat_conte_po h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px;
  color: #fff;
}

.chat_conte_po p {
  margin: 0;
  font-size: 13px;
  /* margin: 0; */
  font-weight: 600;
}

.ma-aut0pdd {
  margin: 11px auto;
}

.chat_conte_po a {
  color: #5d5e65 !important;
}

.chat_box_dark {
  min-height: 338px;
  background: #f6f8fe;
  border-radius: 5px;
  border: 1px solid #f6f8fe;
}

.footer_chat {
  width: 100%;
  padding: 25px;
}

.footer_chat .form-group {
  margin: 0;
}

.footer_chat .form-control {
  background: #e3e6f2;
  padding: 14px 11px;
  border-radius: 5px;
  border: 1px solid #e3e6f2;
  height: 45px;
  color: #666;
  text-transform: capitalize;
}

.button_section_po_chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.chat_message_section {
  min-height: 260px;
  height: 352px;
  overflow: auto;
}

.footer_chat .fa-paper-plane {
  float: right;
  margin-right: 20px;
  margin-top: -31px;
  font-size: 20px;
  color: #0b3e8f;
  cursor: pointer;
}

.footer_chat .fa-paperclip {
  float: left;
  margin-right: 60px;
  margin-top: -31px;
  font-size: 20px;
  color: #0b3e8f;
  cursor: pointer;
}

.message_section {
  padding: 22px 12px;
}

.message_section div {
  width: auto;
  padding: 6px 3px;
  position: relative;
  /* margin: 22px 9px; */
}

.message_section p {
  margin: 0;
  padding: 10px 30px;
  color: #666;
  background: #e3e6f2;
  font-size: 13px;
}

.message_section span {
  position: absolute;
  top: -16px;
  /* width: 100%; */
  right: 4px;
  color: #6f7075;
  font-size: 12px;
}

.message_send span {
  left: 4px !important;
}

.messAge_recive span {
  left: 4px !important;
}

.messAge_recive {
  padding: 22px 12px;
  display: flex;
  justify-content: flex-start;
}

.message_send {
  padding: 22px 12px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .adminNav .dropdown-menu {
    background: transparent;
    margin-top: 0;
    padding-top: 0;
    border: 0;
  }

  .adminNav .dropdown-menu a {
    display: block;
    color: #fff;
    padding: 10px 0;
  }

  .adminNav .dropdown-menu a:hover {
    color: #d6b032;
    text-decoration: none;
  }

  .adminNav .dropdown-item:hover,
  .adminNav .dropdown-item:focus {
    background: transparent;
    text-decoration: none;
  }

  .adminNav.navbar-dark .navbar-toggler {
    color: #fff;
    border: 2px solid #fff;
    padding: 5px 7px;
    margin: 0 0px 0 0;
    line-height: 0;
    font-size: inherit;
    outline: none;
  }

  .navbar.adminNav {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
  }
}

.bg-dark {
  background-color: #54575a !important;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  z-index: 55555;
}

